<template>
  <StaticPagePug>
    <div class="form-wrapper">
      <div class="title">{{ $t('resetPassword.header') }}</div>
      <div class="resetPassword-box" v-show="isAuthorized">
        <div class="from-box" v-show="formFlag">
          <el-form
            label-position="top"
            :model="ruleResetPasswordForm"
            :rules="rulesResetPassword"
            ref="ruleResetPasswordForm"
          >
            <el-form-item :label="$t('changePw.field.newPw')" prop="pass">
              <el-input :type="passwordVisible ? 'text' : 'password'" v-model="ruleResetPasswordForm.pass" autocomplete="off" class="suffix-input" :placeholder="$t('changePw.field.newPw')">
                <template slot="suffix">
                  <img src="@/assets/images/pug/eye-line.png" alt="view" @click="togglePasswordVisibility" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('changePw.field.confirmPw')" prop="checkPass">
              <el-input :type="verifyPasswordVisible ? 'text' : 'password'" v-model="ruleResetPasswordForm.checkPass" autocomplete="off" class="suffix-input" :placeholder="$t('changePw.field.newPw')">
                <template slot="suffix">
                  <img src="@/assets/images/pug/eye-line.png" alt="view" @click="toggleVerifyPasswordVisibility" />
                </template>
              </el-input>
            </el-form-item>
            <p>
              {{ $t('common.formValidation.newPwFormat') }}
            </p>
            <el-button class="ok-btn" @click.native="submitForm('ruleResetPasswordForm')" data-testid="resetPwd">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form>
        </div>
        <div class="reset-success" v-show="successFlag">
          <img src="@/assets/images/pug/success.png" alt />
          <p>{{ $t('resetPassword.succ2') }}</p>
          <router-link :to="{ name: 'login' }" class="el-button ok-btn">{{ $t('common.button.login') }}</router-link>
        </div>
        <LimitWithdrawDialog
          :visible.sync="visible"
          :day="day"
          @changeVisible="changeVisible"
          @confirmPw="confirmPw"
        ></LimitWithdrawDialog>
      </div>
      <p class="no-authroization" v-show="isNotAuthroized">{{ $t('resetPassword.failed') }}</p>
    </div>
  </StaticPagePug>
</template>

<script>
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import { md5 } from '@/util/encrypt';
import { encrypt } from '@/util/rsa/JSEncrypt.js';
import {
  apiCheckResetPasswordAuthorization,
  apiSubmitResetProfilePassword,
  apiGetSecuritysRule,
  apiEncryptPublicKey
} from '@/resource';
import passwordCheckMixin from '@/mixins/passwordCheck';
import { validatePass } from '@/util/validation';
import LimitWithdrawDialog from '@/components/dialog/withdraw.vue';
import withdrawMixin from '@/mixins/withdrawl/withdrawLimit';

export default {
  mixins: [passwordCheckMixin, withdrawMixin],
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        this.isError = true;
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      } else {
        this.isError = false;
        callback();
      }
    };
    return {
      day: '',
      visible: false,
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      isError: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  components: { StaticPagePug, LimitWithdrawDialog },
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    changeVisible(val) {
      this.visible = val;
    },
    async confirmPw() {
      let result = await apiEncryptPublicKey(this.ruleResetPasswordForm.token);
      let publicKey = result.data.data;
      apiSubmitResetProfilePassword(
        {
          token: this.ruleResetPasswordForm.token,
          pass: encrypt(this.ruleResetPasswordForm.pass, publicKey),
          checkPass: encrypt(this.ruleResetPasswordForm.checkPass, publicKey)
        },
        publicKey
      ).then(resp => {
        if (resp.data.code == 0 && resp.data.data.result == true) {
          this.formFlag = false;
          this.successFlag = true;
        }
      });
      this.visible = false;
    },
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: this.$route.query.token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await this.getSecuritysRule();
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
@import '@/assets/css/pages/resetProfilePasswordPug.scss';
</style>
