export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')))
      } else {
        if (!this.schema.validate(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')))
        }
      }
      callback()
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')))
      } else if (value !== this.ruleResetPasswordForm.pass) {
        this.isError = true
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')))
      }
      this.isError = false
      callback()
    }
    return {
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validatePass2, trigger: ['blur', 'change'] }],
      },
    }
  },
}
